// NODE MODULES
import React from 'react';
import Layout from "../layouts/pl";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Fade from 'react-reveal/Fade';

// ICONS
import { FaPhone } from 'react-icons/fa';
import { FaCheck } from 'react-icons/fa';

// COMPONENTS
import { OpinionsPL } from '../components/Opinions';
import PhotoGallery from '../components/Gallery';
import { photos } from "../components/photos";
import { photosMobile } from "../components/photosMobile";

const Dziwnowek = (props) => {

  const data = useStaticQuery(graphql` 
  query {
    desktopImage: file(relativePath: { eq: "images/photos/AA1.JPG" }) {
          childImageSharp {
              fluid(quality: 100){
              ...GatsbyImageSharpFluid
              }
          }   
    }
    mobileImage: file(relativePath: { eq: "images/photos/AA1-mobile.jpeg" }) {
        childImageSharp {
            fluid(quality: 50){
            ...GatsbyImageSharpFluid
            }
        }   
    }
  }`);


  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ];


  return (
    <Layout location={props.location}>
      <>
        <Fade up>
          <section className="area">

            <Img fluid={sources} alt="Dziwnowek apartamenty Amber" />
            <h1 className="title">APARTAMENTY DZIWNÓWEK</h1>
            <div className="content-container">

              <h1>Apartament Amber 1</h1>
              <p> Apartament Amber 1 o powierzchni 40 m2 mieści się na 3 piętrze. Posiada przestronny salon z rozkładaną kanapą, w pełni wyposażony aneks kuchenny, osobną sypialnę z łożem małżeńskim, pojemną szafą i TV, łazienkę, przedpokój z szafą z lustrami oraz balkon z meblami ogrodowymi. Przynależy do niego miejsce postojowe nr 5 w garażu podziemnym w sąsiednim budynku. Widok z balkonu na dziedziniec osiedla.</p>
              <h1>Wyposażenie:</h1>
              <ul>
                <li><FaCheck />klimatyzacja</li>
                <li><FaCheck />smart TV 49’ - salon</li>
                <li><FaCheck />wi-fi</li>
                <li><FaCheck />płyta indukcyjna - 4 palniki</li>
                <li><FaCheck />zmywarka</li>
                <li><FaCheck />ekspres do kawy</li>
                <li><FaCheck />czajnik elektryczny</li>
                <li><FaCheck />toster</li>
                <li><FaCheck />lodówka z zamrażalnikiem</li>
                <li><FaCheck />kuchenka mikrofalowa</li>
                <li><FaCheck />zestaw naczyń do swobodnego przygotowania i spożycia każdego posiłku</li>
                <li><FaCheck />stół jadalniany dla 4 osób</li>
                <li><FaCheck />stolik kawowy</li>
                <li><FaCheck />smart TV 32’ w sypialni</li>
                <li><FaCheck />kabina prysznicowa, umywalka, wc</li>
                <li><FaCheck />pralka oraz suszarka na prania</li>
                <li><FaCheck />suszarka do włosów</li>
                <li><FaCheck />kpl ręczników</li>
                <li><FaCheck />odkurzacz</li>
                <li><FaCheck />żelazko i deska do prasowania</li>
                <li><FaCheck />parawan i namiot plażowy oraz zestaw zabawek dla dzieci</li>
                <li><FaCheck />sofa z możliwością rozłożenia</li>
                <li><FaCheck />łoże małżeńskie</li>
                <li><FaCheck />pojemna szafa</li>
                <li><FaCheck />w korytarzu szafa z lustrami</li>
              </ul>

              <p style={{ marginTop: 20 }}>W apartamencie Amber 1 <b>NIE akceptujemy</b> zwierząt.</p>

            </div>

          </section>
        </Fade>


        <Fade up>
          <section className="photos" id="section3">
            <h1>Galeria</h1>
            <div className="photos">
              <PhotoGallery galleryType='apartament1' />
            </div>
          </section>
        </Fade>


        <Fade up delay={600}>
          <OpinionsPL />
        </Fade>
      </>
    </Layout>
  )
}



export default Dziwnowek
